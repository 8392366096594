html,
body {
  height: 100%;
  width: 100%;
  font-family: 'freight-sans-pro', sans-serif;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  margin: 0;
}

#root {
  min-height: 100%;
  min-width: 100%;
  font-family: 'freight-sans-pro', sans-serif;
}

label {
  font-weight: 500;
}
